// components/PayInvoice.js
import React, { useState } from 'react';
import { Box, VStack, FormControl, FormLabel, Input, Button, Heading } from '@chakra-ui/react';

function PayInvoice() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission and retrieve the invoice based on the provided information
    console.log('First Name:', firstName);
    console.log('Last Name:', lastName);
    console.log('Invoice Number:', invoiceNumber);
    // Add your logic here to retrieve the invoice and display it
  };

  return (
    <Box bg="gray.100" minHeight="100vh" display="flex" justifyContent="center" alignItems="center">
      <Box bg="white" p={8} borderRadius="md" boxShadow="md" maxWidth="500px" width="100%">
        <VStack spacing={6} align="stretch">
          <Heading as="h1" size="xl" textAlign="center" mb={8}>
            Pay Invoice
          </Heading>
          <form onSubmit={handleSubmit}>
            <VStack spacing={4}>
              <FormControl id="firstName" isRequired>
                <FormLabel>First Name</FormLabel>
                <Input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="Enter your first name"
                />
              </FormControl>
              <FormControl id="lastName" isRequired>
                <FormLabel>Last Name</FormLabel>
                <Input
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Enter your last name"
                />
              </FormControl>
              <FormControl id="invoiceNumber" isRequired>
                <FormLabel>Invoice Number</FormLabel>
                <Input
                  type="text"
                  value={invoiceNumber}
                  onChange={(e) => setInvoiceNumber(e.target.value)}
                  placeholder="Enter the invoice number"
                />
              </FormControl>
              <Button type="submit" colorScheme="blue" size="lg" width="100%">
                Retrieve Invoice
              </Button>
            </VStack>
          </form>
        </VStack>
      </Box>
    </Box>
  );
}

export default PayInvoice;