import React, { useState, useEffect } from "react";
import { Box, Heading, Text } from "@chakra-ui/react";

function Dashboard() {
  const [location, setLocation] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = localStorage.getItem("accessToken");
        const locationId = localStorage.getItem("locationId");
        const userId = localStorage.getItem("userId");

        // Fetch location
        const locationResponse = await fetch(
          `https://rest.gohighlevel.com/v1/locations/${locationId}`,
          {
            headers: {
              Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2NhdGlvbl9pZCI6Im5jemdNUHBiMXVEbVNGSzZaOHM3IiwidmVyc2lvbiI6MSwiaWF0IjoxNzE1NDAwNjc0Njc3LCJzdWIiOiJQOTQ4c1Z5RFhEcFZoUTdLSmx3QiJ9.oltQEdCd95-YhaGVhPV1z8J8XAZaObAX36U30fcgD1E`,
            },
          }
        );

        if (!locationResponse.ok) {
          throw new Error("Failed to fetch location");
        }

        const locationData = await locationResponse.json();
        setLocation(locationData);

        // Fetch user
        const userResponse = await fetch(
          `https://services.leadconnectorhq.com/users/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              Version: "2021-07-28",
            },
          }
        );

        if (!userResponse.ok) {
          throw new Error("Failed to fetch user");
        }

        const userData = await userResponse.json();
        setUser(userData);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <Text>Loading...</Text>;
  }

  if (error) {
    return <Text>Error: {error}</Text>;
  }

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <Box>
      <Heading as="h1" size="2xl" mb={4}>
        Hey, {capitalizeFirstLetter(user.firstName)}{" "}
        {capitalizeFirstLetter(user.lastName)}
      </Heading>
      {location && (
        <Box mb={4}>
          <Text>Location Name: {location.name}</Text>
          <Text>Address: {location.address}</Text>
          <Text>City: {location.city}</Text>
          <Text>State: {location.state}</Text>
          <Text>Postal Code: {location.postalCode}</Text>
          <Text>Country: {location.country}</Text>
          <Text>Website: {location.website}</Text>
          <Text>Timezone: {location.timezone}</Text>
        </Box>
      )}
    </Box>
  );
}

export default Dashboard;