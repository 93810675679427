import React, { useState } from 'react';
import { Box, Heading, Image, VStack, FormControl, FormLabel, Input, Button, Text, Link, useToast } from '@chakra-ui/react';
import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { auth, googleProvider } from '../Firebase';
import { useNavigate } from 'react-router-dom';
import googleIcon from '../assets/google.png';

function Login({ doctor }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const toast = useToast();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      // Redirect to the desired page after successful login
      navigate('/patient-portal');
    } catch (error) {
      if (error.code === 'auth/invalid-credential') {
        toast({
          title: 'Invalid credentials',
          description: 'The provided email or password is incorrect. Please enter valid credentials.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } else {
        console.error('Error logging in:', error);
      }
    }
  };

  const handleGoogleLogin = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      // Redirect to the desired page after successful login
      navigate('/patient-portal');
    } catch (error) {
      if (error.code === 'auth/user-not-found') {
        toast({
          title: 'Account does not exist',
          description: 'The account associated with this Google email does not exist. Please sign up to create an account.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } else {
        console.error('Error logging in with Google:', error);
      }
    }
  };

  return (
    <Box bg="gray.100" minHeight="100vh" display="flex" alignItems="center" justifyContent="center">
      <Box bg="white" p={8} borderRadius="md" boxShadow="md" maxWidth="500px" width="100%">
        <VStack spacing={6} align="stretch">
          <Image src={doctor.logo} alt={doctor.name} boxSize="100px" borderRadius="full" alignSelf="center" />
          <Heading as="h1" size="xl" textAlign="center">
            Login
          </Heading>
          <form onSubmit={handleLogin}>
            <VStack spacing={4}>
              <FormControl id="email" isRequired>
                <FormLabel>Email</FormLabel>
                <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
              </FormControl>
              <FormControl id="password" isRequired>
                <FormLabel>Password</FormLabel>
                <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
              </FormControl>
              <Button type="submit" colorScheme="blue" size="lg" width="100%">
                Login
              </Button>
            </VStack>
          </form>
          <Button onClick={handleGoogleLogin} bg="gray.300" colorScheme="gray" size="lg" width="100%" leftIcon={<Image src={googleIcon} alt="Google" boxSize="20px" />}>
            Login with Google
          </Button>
          <Text textAlign="center">
            Don't have an account?{' '}
            <Link color="blue.500" onClick={() => navigate(`/${doctor.slug}/new-patient`)}>
              Sign up
            </Link>
          </Text>
        </VStack>
      </Box>
    </Box>
  );
}

export default Login;