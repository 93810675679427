// components/CalendarDetails.js
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Heading, Text, Stack, IconButton } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import axios from 'axios';

function CalendarDetails() {
  const { calendarId } = useParams();
  const [calendarDetails, setCalendarDetails] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCalendarDetails = async () => {
      const accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
        const options = {
          method: 'GET',
          url: `https://services.leadconnectorhq.com/calendars/${calendarId}`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Version: '2021-04-15',
            Accept: 'application/json',
          },
        };
        try {
          const { data } = await axios.request(options);
          setCalendarDetails(data.calendar);
          console.log('Calendar Details:', data.calendar);
        } catch (error) {
          console.error('Error fetching calendar details:', error);
        }
      } else {
        console.error('Access token not available.');
      }
    };
    fetchCalendarDetails();
  }, [calendarId]);

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  if (!calendarDetails) {
    return <Text>Loading calendar details...</Text>;
  }

  return (
    <Box>
      <Stack direction="row" align="center" mb={4}>
        <IconButton
          icon={<ArrowBackIcon />}
          aria-label="Back"
          onClick={handleBackClick}
          mr={2}
        />
        <Heading as="h1" size="2xl">
          Calendar Details
        </Heading>
      </Stack>
      <Stack spacing={4}>
        <Box>
          <Heading as="h2" size="lg" mb={2}>
            {calendarDetails.name}
          </Heading>
          <Text fontSize="md" mb={2}>
            ID: {calendarDetails.id}
          </Text>
          <Text>{calendarDetails.description}</Text>
        </Box>
        {/* Display other calendar details */}
        <Box>
          <Heading as="h3" size="md" mb={2}>
            Location ID
          </Heading>
          <Text>{calendarDetails.locationId}</Text>
        </Box>
        <Box>
          <Heading as="h3" size="md" mb={2}>
            Group ID
          </Heading>
          <Text>{calendarDetails.groupId}</Text>
        </Box>
        {/* Add more details as needed */}
      </Stack>
    </Box>
  );
}

export default CalendarDetails;