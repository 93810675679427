import React, { useEffect, useState, useRef  } from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  VStack,
  Text,
  Circle,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import {
  FiGrid,
  FiUsers,
  FiSettings,
  FiMail,
  FiBell,
  FiHelpCircle,
  FiSearch,
  FiChevronDown,
} from "react-icons/fi";
import { FaRegCreditCard, FaRegCalendar, FaHeadset } from "react-icons/fa";
import wlogo from "../assets/blogo.png";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

function Main() {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(location.pathname);
  const [userName, setUserName] = useState("");
  const [userRole, setUserRole] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const lastSearchQuery = useRef(searchQuery);

  useEffect(() => {
    const storedAccessToken = localStorage.getItem("accessToken");
    const storedCompanyId = localStorage.getItem("companyId");
    const storedLocationId = localStorage.getItem("locationId");
    const storedUserId = localStorage.getItem("userId");

    if (storedAccessToken) {
    } else {
      const defaultAccessToken = process.env.REACT_APP_ACCESS_TOKEN;
      localStorage.setItem("accessToken", defaultAccessToken);
    }

    if (storedCompanyId) {
    } else {
      const defaultCompanyId = process.env.REACT_APP_COMPANY_ID;
      localStorage.setItem("companyId", defaultCompanyId);
    }

    if (storedLocationId) {
    } else {
      const defaultLocationId = process.env.REACT_APP_LOCATION_ID;
      localStorage.setItem("locationId", defaultLocationId);
    }

    if (storedUserId) {
      fetchUserName(storedUserId, storedAccessToken);
    } else {
      const defaultUserId = process.env.REACT_APP_USER_ID;
      localStorage.setItem("userId", defaultUserId);
      fetchUserName(defaultUserId, storedAccessToken);
    }
  }, []);

  const fetchUserName = async (userId, accessToken) => {
    try {
      const response = await axios.get(
        `https://services.leadconnectorhq.com/users/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Version: "2021-07-28",
            Accept: "application/json",
          },
        }
      );
      const { name, roles } = response.data;
      const capitalizedName = name
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      setUserName(capitalizedName);
      setUserRole(roles.role.charAt(0).toUpperCase() + roles.role.slice(1));
    } catch (error) {
      console.error("Error fetching user name:", error);
    }
  };

  const getInitials = (name) => {
    const names = name.split(" ");
    return names.map((name) => name.charAt(0)).join("");
  };

  useEffect(() => {
    if (searchQuery && searchQuery !== lastSearchQuery.current) {
      navigate(`/portal/search?q=${searchQuery}`);
    }
    lastSearchQuery.current = searchQuery;
  }, [searchQuery, navigate]);

  return (
    <Flex>
      <Flex
        width="300px"
        height="100vh"
        bg="white"
        p={4}
        position="fixed"
        left={0}
        top={0}
        direction="column"
      >
        <Box>
          <Flex justify="center" mt={4}>
            <Image src={wlogo} alt="Logo" mb={8} w="150px" />
          </Flex>
          <VStack spacing={2} align="stretch" flex={1}>
            <Link to="/portal/dashboard">
              <Button
                leftIcon={<FiGrid />}
                fontSize={20}
                bg={activeTab === "/portal/dashboard" ? "#0077b1" : "white"}
                color={activeTab === "/portal/dashboard" ? "white" : "#484848"}
                p={6}
                justifyContent="flex-start"
                _hover={{
                  bg: activeTab === "/portal/dashboard" ? "#005782" : "gray.200",
                }}
                borderRadius={10}
                onClick={() => setActiveTab("/portal/dashboard")}
                w="100%"
              >
                Overview
              </Button>
            </Link>

            <Link to="/portal/patients">
              <Button
                leftIcon={<FiUsers />}
                fontSize={20}
                bg={activeTab === "/portal/patients" ? "#0077b1" : "white"}
                color={activeTab === "/portal/patients" ? "white" : "#484848"}
                p={6}
                justifyContent="flex-start"
                _hover={{
                  bg: activeTab === "/portal/patients" ? "#005782" : "gray.200",
                }}
                borderRadius={10}
                w="100%"
                onClick={() => setActiveTab("/portal/patients")}
              >
                My Patients
              </Button>
            </Link>

            <Link to="/portal/calendars">
              <Button
                leftIcon={<FaRegCalendar />}
                fontSize={20}
                bg={activeTab === "/portal/calendars" ? "#0077b1" : "white"}
                color={activeTab === "/portal/calendars" ? "white" : "#484848"}
                p={6}
                justifyContent="flex-start"
                _hover={{
                  bg: activeTab === "/portal/calendars" ? "#005782" : "gray.200",
                }}
                borderRadius={10}
                w="100%"
                onClick={() => setActiveTab("/portal/calendars")}
              >
                Appointments
              </Button>
            </Link>

            <Link to="/portal/invoices">
              <Button
                leftIcon={<FaRegCreditCard  />}
                fontSize={20}
                bg={activeTab === "/portal/invoices" ? "#0077b1" : "white"}
                color={activeTab === "/portal/invoices" ? "white" : "#484848"}
                p={6}
                justifyContent="flex-start"
                _hover={{
                  bg: activeTab === "/portal/invoices" ? "#005782" : "gray.200",
                }}
                borderRadius={10}
                w="100%"
                onClick={() => setActiveTab("/portal/invoices")}
              >
                Payments
              </Button>
            </Link>

            <Link to="/portal/messages">
              <Button
                leftIcon={<FiMail />}
                fontSize={20}
                bg={activeTab === "/portal/messages" ? "#0077b1" : "white"}
                color={activeTab === "/portal/messages" ? "white" : "#484848"}
                p={6}
                justifyContent="flex-start"
                _hover={{
                  bg: activeTab === "/portal/messages" ? "#005782" : "gray.200",
                }}
                borderRadius={10}
                w="100%"
                onClick={() => setActiveTab("/portal/messages")}
              >
                Messages
              </Button>
            </Link>

            <Link to="/portal/voice-agent">
              <Button
                leftIcon={<FaHeadset />}
                fontSize={20}
                bg={activeTab === "/portal/voice-agent" ? "#0077b1" : "white"}
                color={activeTab === "/portal/voice-agent" ? "white" : "#484848"}
                p={6}
                justifyContent="flex-start"
                _hover={{
                  bg: activeTab === "/portal/voice-agent" ? "#005782" : "gray.200",
                }}
                borderRadius={10}
                w="100%"
                onClick={() => setActiveTab("/portal/voice-agent")}
              >
                Voice Agent
              </Button>
            </Link>

            <Link to="/portal/settings">
              <Button
                leftIcon={<FiSettings />}
                fontSize={20}
                bg={activeTab === "/portal/settings" ? "#0077b1" : "white"}
                color={activeTab === "/portal/settings" ? "white" : "#484848"}
                p={6}
                justifyContent="flex-start"
                _hover={{
                  bg: activeTab === "/portal/settings" ? "#005782" : "gray.200",
                }}
                borderRadius={10}
                w="100%"
                onClick={() => setActiveTab("/portal/settings")}
              >
                Settings
              </Button>
            </Link>
          </VStack>
        </Box>
      </Flex>
      <Flex
        ml="300px"
        width="calc(100% - 300px)"
        bg="#f5f5f5"
        h="100vh"
        direction="column"
      >
        <Flex alignItems="center" pt={6} px={6}>
          <InputGroup mr={10} size="lg" borderColor="#f5f5f5">
            <InputLeftElement>
              <FiSearch fontSize="22px"/>
            </InputLeftElement>
            <Input 
              placeholder="Search Patients, Appointments, Payments or Conversation"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </InputGroup>
          <Flex ml="auto" alignItems="center">
            <IconButton
              aria-label="Notifications"
              icon={<FiBell />}
              variant="ghost"
              mr={4}
              fontSize="22px"
              color="#222222"
            />
            <IconButton
              aria-label="Help"
              icon={<FiHelpCircle />}
              variant="ghost"
              mr={10}
              fontSize="22px"
              color="#222222"
            />
            <Circle size="50px" bg="orange.500" color="white" mr={4}>
              <Text fontSize="xl" fontWeight="bold">
                {getInitials(userName)}
              </Text>
            </Circle>
            <Flex alignItems="center" mr={4}>
              <Flex direction="column" alignItems="flex-start" mr={4}>
                <Text fontSize="lg" fontWeight="bold" isTruncated noOfLines={1} lineHeight="1.2" mb={2}>
                  {userName}
                </Text>
                <Text fontSize="md" lineHeight="1.2">
                  {userRole}
                </Text>
              </Flex>
              <Menu>
                <MenuButton as={Button} variant="ghost" alignSelf="center">
                  <FiChevronDown fontSize="28px" />
                </MenuButton>
                <MenuList>
                  <MenuItem>Dummy Option 1</MenuItem>
                  <MenuItem>Dummy Option 2</MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          </Flex>
        </Flex>
        <Box p={6}>
          <Outlet />
        </Box>
      </Flex>
    </Flex>
  );
}

export default Main;