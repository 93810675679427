// components/Calendars.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Heading, Text, Stack, Code } from '@chakra-ui/react';
import axios from 'axios';

function Calendars() {
  const [calendarData, setCalendarData] = useState(null);
  const [calendarEvents, setCalendarEvents] = useState({});

  useEffect(() => {
    const fetchCalendarData = async () => {
      const accessToken = localStorage.getItem('accessToken');
      const locationId = localStorage.getItem('locationId');

      if (accessToken && locationId) {
        const options = {
          method: 'GET',
          url: 'https://services.leadconnectorhq.com/calendars/',
          params: { locationId },
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Version: '2021-04-15',
            Accept: 'application/json',
          },
        };

        try {
          const { data } = await axios.request(options);
          setCalendarData(data);
          console.log('Calendar Data:', data);
        } catch (error) {
          console.error('Error fetching calendar data:', error);
        }
      } else {
        console.error('Access token or location ID not available.');
      }
    };

    fetchCalendarData();
  }, []);

  useEffect(() => {
    const fetchCalendarEvents = async () => {
      const accessToken = localStorage.getItem('accessToken');
      const locationId = localStorage.getItem('locationId');

      if (accessToken && locationId && calendarData) {
        for (const calendar of calendarData.calendars) {
          const options = {
            method: 'GET',
            url: 'https://services.leadconnectorhq.com/calendars/events',
            params: {
              calendarId: calendar.id,
              locationId,
              startTime: 1715585089000,
              endTime: 1755585089000,
            },
            headers: {
              Authorization: `Bearer ${accessToken}`,
              Version: '2021-04-15',
              Accept: 'application/json',
            },
          };

          try {
            const { data } = await axios.request(options);
            setCalendarEvents((prevEvents) => ({
              ...prevEvents,
              [calendar.id]: data,
            }));
            console.log(`Calendar Events for ${calendar.id}:`, data);
          } catch (error) {
            console.error(`Error fetching events for calendar ${calendar.id}:`, error);
          }
        }
      }
    };

    fetchCalendarEvents();
  }, [calendarData]);

  return (
    <Box>
      <Heading as="h1" size="2xl" mb={4}>
        Calendars
      </Heading>
      {calendarData ? (
        <Stack spacing={8}>
          {calendarData.calendars.map((calendar) => (
            <Box key={calendar.id} p={4} borderWidth={1} borderRadius="md">
              <Link to={`/portal/calendars/${calendar.id}`}>
                <Heading as="h2" size="lg" mb={2}>
                  {calendar.name}
                </Heading>
              </Link>
              <Text fontSize="md" mb={2}>
                ID: {calendar.id}
              </Text>
              <Text>{calendar.description}</Text>
              {calendarEvents[calendar.id] && (
                <Box mt={4}>
                  <Heading as="h3" size="md" mb={2}>
                    API Response:
                  </Heading>
                  <Code display="block" whiteSpace="pre" p={2}>
                    {JSON.stringify(calendarEvents[calendar.id], null, 2)}
                  </Code>
                </Box>
              )}
            </Box>
          ))}
        </Stack>
      ) : (
        <Text>Loading calendar data...</Text>
      )}
    </Box>
  );
}

export default Calendars;