// components/Invoices.js
import React, { useEffect, useState } from 'react';
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  Input,
  Button,
  Flex,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function formatDate(dateString) {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(dateString).toLocaleDateString(undefined, options);
}

function Invoices() {
  const [invoices, setInvoices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchInvoices();
  }, []);

  const fetchInvoices = async (query = '') => {
    const accessToken = localStorage.getItem('accessToken');
    const companyId = localStorage.getItem('companyId');
    const locationId = localStorage.getItem('locationId');
    const altId = locationId || companyId;
    const altType = locationId ? 'location' : 'company';

    try {
      const response = await axios.get('https://services.leadconnectorhq.com/invoices/', {
        params: {
          altId,
          altType,
          limit: 10,
          offset: 0,
          search: query,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Version: '2021-07-28',
          Accept: 'application/json',
        },
      });
      setInvoices(response.data.invoices);
    } catch (error) {
      console.error('Error fetching invoices:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = () => {
    fetchInvoices(searchQuery);
  };

  const handleRowClick = (invoiceId) => {
    navigate(`/portal/invoices/${invoiceId}`);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleAddNewInvoice = () => {
    navigate('/portal/invoices/new-invoice');
  };

  return (
    <Box>
      <Heading as="h1" size="2xl" mb={4}>
        Invoices
      </Heading>
      <Flex justify="space-between" mb={4}>
        <Flex>
          <Input
            placeholder="Search Invoices"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={handleKeyDown}
            width="400px"
            mr={2}
          />
          <Button onClick={handleSearch}>Search</Button>
        </Flex>
        <Flex>
          <Button colorScheme="blue" onClick={handleAddNewInvoice}>
            Add New Invoice
          </Button>
        </Flex>
      </Flex>
      {isLoading ? (
        <Spinner size="xl" />
      ) : (
        <Table>
          <Thead>
            <Tr>
              <Th>Invoice Number</Th>
              <Th>Status</Th>
              <Th>Amount Due</Th>
              <Th>Issue Date</Th>
            </Tr>
          </Thead>
          <Tbody>
            {invoices.map((invoice) => (
              <Tr
                key={invoice._id}
                cursor="pointer"
                _hover={{ bg: 'gray.100' }}
                onClick={() => handleRowClick(invoice._id)}
              >
                <Td>{invoice.invoiceNumber}</Td>
                <Td>{invoice.status}</Td>
                <Td>{invoice.amountDue}</Td>
                <Td>{formatDate(invoice.issueDate)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </Box>
  );
}

export default Invoices;