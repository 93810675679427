import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBh58iuwDCj3pt_TUPXbPEPLha5Yg11KKo",
  authDomain: "usemedify.firebaseapp.com",
  projectId: "usemedify",
  storageBucket: "usemedify.appspot.com",
  messagingSenderId: "1045988012278",
  appId: "1:1045988012278:web:cde64be4eb20f69051bcd7"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();

export { auth, db, googleProvider };