// components/NewInvoice.js
import React from 'react';
import { Box, Heading, Text, IconButton, Stack } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';

function NewInvoice() {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <Box>
      <Stack direction="row" align="center" mb={4}>
        <IconButton
          icon={<ArrowBackIcon />}
          aria-label="Back"
          onClick={handleBackClick}
          mr={2}
        />
        <Heading as="h1" size="2xl">
            New Invoice
        </Heading>
      </Stack>
      <Text>This is the page for adding a new invoice.</Text>
    </Box>
  );
}

export default NewInvoice;