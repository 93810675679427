// components/Home.js
import React, { useEffect } from 'react';
import { Box, Button, Flex, Heading, Image, VStack, Center } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/wlogo.png';

function Home() {
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the access token is already stored in local storage
    const storedAccessToken = localStorage.getItem('accessToken');
    if (storedAccessToken) {
      // If the access token is already stored, redirect to the dashboard
      navigate('/portal');
    }
  }, [navigate]);

  const handleOAuthLogin = () => {
    // Redirect to the HighLevel Authorization Page URL
    window.location.href =
      'https://marketplace.leadconnectorhq.com/oauth/chooselocation?response_type=code&redirect_uri=https://www.medify.dev/oauth&client_id=663fa7e0249783c1f8305b2d-lw2dd1jy&scope=calendars.write calendars.readonly conversations/message.readonly conversations/message.write conversations.write conversations/reports.readonly conversations.readonly users.readonly users.write contacts.readonly contacts.write invoices.readonly invoices.write invoices/schedule.readonly invoices/schedule.write invoices/template.readonly invoices/template.write companies.readonly businesses.readonly businesses.write calendars/events.readonly calendars/events.write calendars/groups.readonly calendars/groups.write calendars/resources.readonly calendars/resources.write locations.write locations.readonly locations/customValues.readonly locations/customValues.write locations/customFields.readonly locations/customFields.write locations/tasks.readonly locations/tasks.write locations/tags.readonly locations/tags.write locations/templates.readonly payments/orders.readonly payments/orders.write payments/integration.readonly payments/integration.write payments/transactions.readonly payments/subscriptions.readonly products.readonly products.write products/prices.readonly products/prices.write';
  };

  return (
    <Flex minHeight="100vh">
      <Box flex={1} bg="gray.100" p={8}>
        <VStack spacing={8} align="stretch" justifyContent="center" height="100%">
          <Heading fontSize={60} textAlign="center">
            Your Practice Made Easy
          </Heading>
          <Center>
            <Button colorScheme="blue" size="lg" onClick={handleOAuthLogin} fontSize={32} p={10}>
              Enter Your Admin Dashboard
            </Button>
          </Center>
        </VStack>
      </Box>
      <Box flex={1} bg="blue.500" display="flex" alignItems="center" justifyContent="center">
        <Image src={logo} alt="Medify Logo" maxWidth="50%" maxHeight="50%" />
      </Box>
    </Flex>
  );
}

export default Home;

