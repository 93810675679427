// components/Settings.js
import React, { useState, useEffect } from 'react';
import { Box, Heading, Button, Text, VStack, HStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Settings = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);

  const accessToken = localStorage.getItem('accessToken');
  const companyId = localStorage.getItem('companyId');
  const locationId = localStorage.getItem('locationId');
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `https://services.leadconnectorhq.com/users/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              Version: '2021-07-28',
              Accept: 'application/json',
            },
          }
        );
        setUserData(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    if (userId && accessToken) {
      fetchUserData();
    }
  }, [userId, accessToken]);

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('companyId');
    localStorage.removeItem('locationId');
    localStorage.removeItem('userId');
    navigate('/');
  };

  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
  };

  return (
    <Box>
      <Heading as="h1" size="2xl" mb={4}>
        Settings
      </Heading>
      {userData && (
        <VStack align="stretch" spacing={4} mb={8}>
          <Box>
            <Text fontWeight="bold">Name:</Text>
            <Text>{userData.name}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Email:</Text>
            <Text>{userData.email}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Phone:</Text>
            <Text>{userData.phone}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Role:</Text>
            <Text>{userData.roles.role}</Text>
          </Box>
        </VStack>
      )}
      <VStack align="stretch" spacing={4} mb={8}>
        <Box>
          <Text fontWeight="bold">Access Token:</Text>
          <HStack>
            <Text>{accessToken ? `${accessToken.slice(0, 10)}...` : 'Not available'}</Text>
            <Button size="sm" onClick={() => copyToClipboard(accessToken)}>
              Copy
            </Button>
          </HStack>
        </Box>
        <Box>
          <Text fontWeight="bold">Company ID:</Text>
          <HStack>
            <Text>{companyId ? `${companyId.slice(0, 10)}...` : 'Not available'}</Text>
            <Button size="sm" onClick={() => copyToClipboard(companyId)}>
              Copy
            </Button>
          </HStack>
        </Box>
        <Box>
          <Text fontWeight="bold">Location ID:</Text>
          <HStack>
            <Text>{locationId ? `${locationId.slice(0, 10)}...` : 'Not available'}</Text>
            <Button size="sm" onClick={() => copyToClipboard(locationId)}>
              Copy
            </Button>
          </HStack>
        </Box>
        <Box>
          <Text fontWeight="bold">User ID:</Text>
          <HStack>
            <Text>{userId ? `${userId.slice(0, 10)}...` : 'Not available'}</Text>
            <Button size="sm" onClick={() => copyToClipboard(userId)}>
              Copy
            </Button>
          </HStack>
        </Box>
      </VStack>
      <Button colorScheme="red" onClick={handleLogout}>
        Logout
      </Button>
    </Box>
  );
};

export default Settings;