import React from 'react';
import { Box, Button, VStack, Image, Heading } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import logo from '../assets/blogo.png';

function LandingPage({ doctors }) {
  return (
    <Box
      minHeight="100vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Image src={logo} alt="Medify Logo" mb={8} maxW="200px" />
      <Heading as="h1" size="2xl" mb={8} color="blue.500">
        Welcome to Medify
      </Heading>
      <VStack spacing={4}>
        {doctors.map((doctor) => (
          <Button
            key={doctor.slug}
            as={Link}
            to={`/${doctor.slug}`}
            colorScheme="blue"
            size="lg"
            px={8}
            py={6}
            borderRadius="full"
            fontWeight="bold"
            textTransform="uppercase"
            letterSpacing="wide"
            boxShadow="md"
            _hover={{ boxShadow: 'lg' }}
          >
            {doctor.name}
          </Button>
        ))}
      </VStack>
      <Button
        as={Link}
        to="/admin"
        mt={8}
        colorScheme="gray"
        size="md"
        fontWeight="medium"
      >
        Admin
      </Button>
    </Box>
  );
}

export default LandingPage;