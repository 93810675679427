import React from 'react';
import { Box, Heading, Image, VStack, Text, Button, Grid, GridItem, Icon } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { FaUserCircle, FaFileInvoiceDollar, FaUserPlus } from 'react-icons/fa';

function DoctorLanding({ doctor }) {
  return (
    <Box bg="gray.100" minHeight="100vh">
      <Box bg="white" py={8} boxShadow="md">
        <VStack spacing={8} align="center">
          <Image src={doctor.logo} alt={doctor.name} w="150px"/>
          <Heading as="h1" size="xl">
            Welcome to {doctor.name}'s Dashboard
          </Heading>
        </VStack>
      </Box>
      <Box maxWidth="1200px" margin="0 auto" py={12}>
        <Grid templateColumns="repeat(3, 1fr)" gap={8}>
          <GridItem>
            <Box bg="white" p={8} borderRadius="md" boxShadow="md" textAlign="center">
              <Icon as={FaUserCircle} boxSize={12} color="blue.500" mb={4} />
              <Heading as="h2" size="lg" mb={4}>
                Login
              </Heading>
              <Text fontSize="lg" mb={6}>
                Access your account and manage your health information.
              </Text>
              <Button colorScheme="blue" size="lg" as={Link} to={`/${doctor.slug}/login`}>
                Login
              </Button>
            </Box>
          </GridItem>
          <GridItem>
            <Box bg="white" p={8} borderRadius="md" boxShadow="md" textAlign="center">
              <Icon as={FaUserPlus} boxSize={12} color="green.500" mb={4} />
              <Heading as="h2" size="lg" mb={4}>
                Sign Up
              </Heading>
              <Text fontSize="lg" mb={6}>
                Create a new account and join our healthcare platform.
              </Text>
              <Button colorScheme="green" size="lg" as={Link} to={`/${doctor.slug}/new-patient`}>
                Sign Up
              </Button>
            </Box>
          </GridItem>
          <GridItem>
            <Box bg="white" p={8} borderRadius="md" boxShadow="md" textAlign="center">
              <Icon as={FaFileInvoiceDollar} boxSize={12} color="orange.500" mb={4} />
              <Heading as="h2" size="lg" mb={4}>
                Pay Invoice
              </Heading>
              <Text fontSize="lg" mb={6}>
                Securely pay your outstanding invoices online.
              </Text>
              <Button colorScheme="orange" size="lg" as={Link} to={`/${doctor.slug}/pay-invoice`}>
                Pay Invoice
              </Button>
            </Box>
          </GridItem>
        </Grid>
      </Box>
    </Box>
  );
}

export default DoctorLanding;