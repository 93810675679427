import React, { useEffect, useState } from 'react';
import { Box, Heading, Text, VStack, Button, Avatar, Flex, Grid, GridItem } from '@chakra-ui/react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth, db } from '../Firebase';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';

function PatientPortal() {
  const [patientData, setPatientData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        // Fetch patient data from Firestore
        const patientDoc = await getDoc(doc(db, 'patients', currentUser.uid));
        if (patientDoc.exists()) {
          setPatientData(patientDoc.data());
        }
      } else {
        // If the user is not logged in, redirect to the root route ("/")
        navigate('/');
      }
    });

    return () => {
      unsubscribe();
    };
  }, [navigate]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      // Redirect to the root route ("/") after successful logout
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <Box bg="gray.100" minHeight="100vh">
      <Box bg="white" py={8} boxShadow="md">
        <VStack spacing={8} align="center">
          <Avatar size="2xl" name={patientData?.firstName + ' ' + patientData?.lastName} />
          <Heading as="h1" size="xl">
            Welcome, {patientData?.firstName} {patientData?.lastName}!
          </Heading>
        </VStack>
      </Box>
      <Box maxWidth="1200px" margin="0 auto" py={12}>
        <Grid templateColumns="repeat(2, 1fr)" gap={8}>
          <GridItem>
            <Box bg="white" p={8} borderRadius="md" boxShadow="md">
              <Heading as="h2" size="lg" mb={4}>
                Appointments
              </Heading>
              <Text fontSize="lg" mb={6}>
                View and manage your upcoming appointments.
              </Text>
              <Button colorScheme="blue" size="lg">
                View Appointments
              </Button>
            </Box>
          </GridItem>
          <GridItem>
            <Box bg="white" p={8} borderRadius="md" boxShadow="md">
              <Heading as="h2" size="lg" mb={4}>
                Medical Records
              </Heading>
              <Text fontSize="lg" mb={6}>
                Access your medical records and health information.
              </Text>
              <Button colorScheme="green" size="lg">
                View Medical Records
              </Button>
            </Box>
          </GridItem>
        </Grid>
      </Box>
      <Flex justify="center" mt={8}>
        <Button onClick={handleLogout} colorScheme="red" size="lg">
          Logout
        </Button>
      </Flex>
    </Box>
  );
}

export default PatientPortal;