// components/Search.js
import React, { useEffect, useState } from 'react';
import { Box, Text, Table, Thead, Tbody, Tr, Th, Td, Spinner, Flex, Heading } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

function Search() {
  const [contactsData, setContactsData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDelaying, setIsDelaying] = useState(false);
  const location = useLocation();
  const searchQuery = new URLSearchParams(location.search).get('q');

  useEffect(() => {
    setIsDelaying(true);
    const timer = setTimeout(() => {
      setIsDelaying(false);
      fetchContactsData(searchQuery);
    }, 1000);

    return () => clearTimeout(timer);
  }, [searchQuery]);

  const fetchContactsData = async (query) => {
    setIsLoading(true);
    const storedAccessToken = localStorage.getItem('accessToken');
    const locationId = localStorage.getItem('locationId');

    if (storedAccessToken && locationId) {
      const options = {
        method: 'GET',
        url: 'https://services.leadconnectorhq.com/contacts/',
        params: { locationId, query },
        headers: {
          Authorization: `Bearer ${storedAccessToken}`,
          Version: '2021-07-28',
          Accept: 'application/json',
        },
      };

      try {
        const { data } = await axios.request(options);
        setContactsData(data);
        console.log('Contacts Data:', data);
      } catch (error) {
        console.error('Error fetching contacts data:', error);
      } finally {
        setIsLoading(false);
      }
    } else {
      console.error('Access token or location ID not available.');
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <Heading as="h1" size="2xl" mb={4}>
        Search
      </Heading>
      {isDelaying || isLoading ? (
        <Flex justifyContent="center" alignItems="center" height="200px">
          <Spinner size="xl" />
        </Flex>
      ) : contactsData ? (
        contactsData.contacts.length > 0 ? (
          <Table>
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Email</Th>
                <Th>Phone</Th>
              </Tr>
            </Thead>
            <Tbody>
              {contactsData.contacts.map((contact) => (
                <Tr key={contact.id}>
                  <Td>
                    {contact.firstName} {contact.lastName}
                  </Td>
                  <Td>{contact.email}</Td>
                  <Td>{contact.phone}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : (
          <Text>No results found.</Text>
        )
      ) : null}
    </Box>
  );
}

export default Search;