import React, { useState, useEffect } from 'react';
import { Box, Heading, Text, IconButton, Stack, Input, Button, FormControl, FormLabel } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';

function NewPatient() {
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    age: '',
    gender: '',
    locationId: '', // Added locationId field
  });

  // Retrieve the locationId from local storage
  useEffect(() => {
    const storedLocationId = localStorage.getItem('locationId');
    if (storedLocationId) {
      setFormData((prevFormData) => ({ ...prevFormData, locationId: storedLocationId }));
    }
  }, []);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here, you can implement the logic to save the new patient data
    // For example, you could make an API call or update the state in a parent component
    console.log(formData);
    // Reset the form after submission
    setFormData({ firstName: '', lastName: '', email: '', phone: '', age: '', gender: '', locationId: formData.locationId });
  };

  return (
    <Box>
      <Stack direction="row" align="center" mb={4}>
        <IconButton icon={<ArrowBackIcon />} aria-label="Back" onClick={handleBackClick} mr={2} />
        <Heading as="h1" size="2xl">
          New Patient
        </Heading>
      </Stack>
      <form onSubmit={handleSubmit}>
        <FormControl mb={4}>
          <FormLabel>First Name</FormLabel>
          <Input type="text" name="firstName" value={formData.firstName} onChange={handleInputChange} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>Last Name</FormLabel>
          <Input type="text" name="lastName" value={formData.lastName} onChange={handleInputChange} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>Email</FormLabel>
          <Input type="email" name="email" value={formData.email} onChange={handleInputChange} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>Phone</FormLabel>
          <Input type="tel" name="phone" value={formData.phone} onChange={handleInputChange} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>Age</FormLabel>
          <Input type="number" name="age" value={formData.age} onChange={handleInputChange} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>Gender</FormLabel>
          <Input type="text" name="gender" value={formData.gender} onChange={handleInputChange} />
        </FormControl>
        {/* Add more input fields as needed */}
        <Button type="submit" colorScheme="blue">
          Save Patient
        </Button>
      </form>
    </Box>
  );
}

export default NewPatient;