import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Select,
  Input,
  Spinner,
  VStack,
  HStack,
  Heading,
} from '@chakra-ui/react';

const Messages = () => {
  const [accessToken, setAccessToken] = useState('');
  const [locationId, setLocationId] = useState('');
  const [conversations, setConversations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const storedAccessToken = localStorage.getItem('accessToken');
    const storedLocationId = localStorage.getItem('locationId');

    if (storedAccessToken && storedLocationId) {
      setAccessToken(storedAccessToken);
      setLocationId(storedLocationId);
    }
  }, []);

  useEffect(() => {
    const fetchConversations = async () => {
      const url = `https://services.leadconnectorhq.com/conversations/search?locationId=${locationId}&status=${filter}&query=${searchQuery}`;

      try {
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Version: '2021-04-15',
            Accept: 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setConversations(data.conversations);
        } else {
          console.error('Error fetching conversations:', response.status);
        }
      } catch (error) {
        console.error('Error fetching conversations:', error);
      } finally {
        setLoading(false);
      }
    };

    if (accessToken && locationId) {
      fetchConversations();
    }
  }, [accessToken, locationId, filter, searchQuery]);

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <Box>
      <Heading>Messages</Heading>
      <HStack mb={4}>
        <Select value={filter} onChange={handleFilterChange}>
          <option value="all">All</option>
          <option value="read">Read</option>
          <option value="unread">Unread</option>
          <option value="starred">Starred</option>
          <option value="recents">Recents</option>
        </Select>
        <Input
          placeholder="Search conversations"
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </HStack>
      {loading ? (
        <Spinner />
      ) : (
        <VStack spacing={4} align="stretch">
          {conversations.map((conversation) => (
            <Box key={conversation.id} bg="gray.100" p={4} borderRadius="md">
              <Text>{conversation.lastMessageBody}</Text>
            </Box>
          ))}
        </VStack>
      )}
    </Box>
  );
};

export default Messages;