// components/OAuthCallback.js
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Box, Spinner, Image, Flex } from '@chakra-ui/react';
import logo from '../assets/blogo.png';

function OAuthCallback() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const authorizationCode = searchParams.get('code');

    // Check if the access token is already stored in local storage
    const storedAccessToken = localStorage.getItem('accessToken');
    if (storedAccessToken) {
      // If the access token is already stored, redirect to the dashboard
      navigate('/portal');
    } else if (authorizationCode) {
      // If the authorization code is present in the URL, exchange it for an access token
      const payload = new URLSearchParams();
      payload.append('client_id', '663fa7e0249783c1f8305b2d-lw2dd1jy');
      payload.append('client_secret', '492e55ac-7812-4304-be5c-3c127772745f');
      payload.append('grant_type', 'authorization_code');
      payload.append('code', authorizationCode);
      payload.append('redirect_uri', 'https://www.medify.dev/oauth');

      axios.post('https://services.leadconnectorhq.com/oauth/token', payload, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
        .then(response => {
          console.log(response);
          const accessToken = response.data.access_token;
          const companyId = response.data.companyId;
          const locationId = response.data.locationId;
          const userId = response.data.userId;
          localStorage.setItem('accessToken', accessToken);
          localStorage.setItem('companyId', companyId);
          localStorage.setItem('locationId', locationId);
          localStorage.setItem('userId', userId);
          navigate('/portal');
        })
        .catch(error => {
          console.error('Error exchanging authorization code for access token:', error);
          // If an error occurs, redirect to the home page
          navigate('/');
        });
    } else {
      // If neither the access token nor the authorization code is present, redirect to the home page
      navigate('/');
    }
  }, [location, navigate]);

  return (
    <Flex
      minHeight="100vh"
      alignItems="center"
      justifyContent="center"
      bg="gray.100"
    >
      <Box textAlign="center">
        <Box>Logging you into...</Box>
        <Image src={logo} alt="Logo" mx="auto" mt={4} w="150px" />
        <Spinner size="xl" mt={4} />
      </Box>
    </Flex>
  );
}

export default OAuthCallback;