// components/InvoiceDetails.js
import React, { useEffect, useState } from 'react';
import { Box, Heading, Text, IconButton, Stack, Spinner } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

function formatDate(dateString) {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(dateString).toLocaleDateString(undefined, options);
}

function InvoiceDetails() {
  const navigate = useNavigate();
  const { invoiceId } = useParams();
  const [invoice, setInvoice] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchInvoiceDetails = async () => {
      const accessToken = localStorage.getItem('accessToken');
      const locationId = localStorage.getItem('locationId');

      try {
        const response = await axios.get(`https://services.leadconnectorhq.com/invoices/${invoiceId}`, {
          params: { altId: locationId, altType: 'location' },
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Version: '2021-07-28',
            Accept: 'application/json',
          },
        });
        setInvoice(response.data);
      } catch (error) {
        console.error('Error fetching invoice details:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInvoiceDetails();
  }, [invoiceId]);

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  const handleContactClick = () => {
    navigate(`/portal/patients/${invoice.contactDetails.id}`);
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <Spinner size="xl" />
      </Box>
    );
  }

  if (!invoice) {
    return (
      <Box>
        <Text>Invoice not found.</Text>
      </Box>
    );
  }

  return (
    <Box>
      <Stack direction="row" align="center" mb={4}>
        <IconButton icon={<ArrowBackIcon />} aria-label="Back" onClick={handleBackClick} mr={2} />
        <Heading as="h1" size="2xl">
          Invoice Details
        </Heading>
      </Stack>
      <Box bg="white" p={6} borderRadius="md" boxShadow="md">
        <Stack spacing={4}>
          <Box>
            <Text fontWeight="bold">Invoice Number:</Text>
            <Text>{invoice.invoiceNumber}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Status:</Text>
            <Text>{invoice.status}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Issue Date:</Text>
            <Text>{formatDate(invoice.issueDate)}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Due Date:</Text>
            <Text>{formatDate(invoice.dueDate)}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Amount Due:</Text>
            <Text>{invoice.amountDue}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Sent To:</Text>
            <Box
              cursor="pointer"
              _hover={{ textDecoration: 'underline' }}
              onClick={handleContactClick}
            >
              <Text>{invoice.contactDetails.name}</Text>
              <Text>{invoice.contactDetails.email}</Text>
            </Box>
          </Box>
          <Box>
            <Text fontWeight="bold">Sent From:</Text>
            <Text>{invoice.sentFrom.fromName}</Text>
            <Text>{invoice.sentFrom.fromEmail}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Sent At:</Text>
            <Text>{formatDate(invoice.sentAt)}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Invoice Items:</Text>
            {invoice.invoiceItems.map((item) => (
              <Box key={item._id} ml={4}>
                <Text fontWeight="bold">{item.name}</Text>
                <Text>Quantity: {item.qty}</Text>
                <Text>Amount: {item.amount}</Text>
              </Box>
            ))}
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}

export default InvoiceDetails;