import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import LandingPage from './components/LandingPage';
import Home from './components/Home';
import OAuthCallback from './components/OAuthCallback';
import Main from './components/Main';
import Dashboard from './components/Dashboard';
import Patients from './components/Patients';
import Calendars from './components/Calendars';
import Invoices from './components/Invoices';
import Settings from './components/Settings';
import CalendarDetails from './components/CalendarDetails';
import PatientDetails from './components/PatientDetails';
import NewPatient from './components/NewPatient';
import InvoiceDetails from './components/InvoiceDetails';
import NewInvoice from './components/NewInvoice';
import Search from './components/Search';
import Messages from './components/Messages';
import Login from './patient-portal/Login';
import PayInvoice from './patient-portal/PayInvoice';
import DoctorLanding from './patient-portal/DoctorLanding';
import Signup from './patient-portal/Signup';
import PatientPortal from './patient-portal/PatientPortal';

// Import doctor logos
import drGuildLogo from './assets/dr-guild.png';
import drMikeLogo from './assets/dr-mike.jpg';
import VoiceAgent from './components/VoiceAgent';

// Custom theme
const theme = extendTheme({
  fonts: {
    body: 'Reddit Sans, sans-serif',
    heading: 'Reddit Sans, sans-serif',
  },
  colors: {
    body: '#484848',
    heading: '#222222',
  },
});

const doctors = [
  { name: 'Dr Guilds Business', slug: 'dr-guild', logo: drGuildLogo },
  { name: 'Dr Mikes Business', slug: 'dr-mike', logo: drMikeLogo },
  { name: 'Dr Chris Business', slug: 'dr-chris', logo: drMikeLogo },
];

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage doctors={doctors} />} />
          <Route path="/admin" element={<Home />} />
          {doctors.map((doctor) => (
            <Route key={doctor.slug} path={`/${doctor.slug}`}>
              <Route path="" element={<DoctorLanding doctor={doctor} />} />
              <Route path="login" element={<Login doctor={doctor} />} />
              <Route path="new-patient" element={<Signup doctor={doctor} />} />
              <Route path="pay-invoice" element={<PayInvoice doctor={doctor} />} />
            </Route>
          ))}
          <Route path="/patient-portal" element={<PatientPortal />} />
          <Route path="/oauth" element={<OAuthCallback />} />
          <Route path="/portal" element={<Main />}>
            <Route path="" element={<Navigate to="dashboard" replace />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="patients" element={<Patients />} />
            <Route path="patients/new-patient" element={<NewPatient />} />
            <Route path="patients/:contactId" element={<PatientDetails />} />
            <Route path="calendars" element={<Calendars />} />
            <Route path="calendars/:calendarId" element={<CalendarDetails />} />
            <Route path="invoices" element={<Invoices />} />
            <Route path="invoices/new-invoice" element={<NewInvoice />} />
            <Route path="invoices/:invoiceId" element={<InvoiceDetails />} />
            <Route path="messages" element={<Messages />} />
            <Route path="voice-agent" element={<VoiceAgent />} />
            <Route path="search" element={<Search />} />
            <Route path="settings" element={<Settings />} />
          </Route>
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;