// components/PatientDetails.js
import React, { useEffect, useState } from 'react';
import { Box, Heading, Text, VStack, HStack, Divider, IconButton, Input, Button } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

function formatDate(dateString) {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(dateString).toLocaleDateString(undefined, options);
}

function PatientDetails() {
  const { contactId } = useParams();
  const [contactDetails, setContactDetails] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchContactDetails = async () => {
      const accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
        const options = {
          method: 'GET',
          url: `https://services.leadconnectorhq.com/contacts/${contactId}`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Version: '2021-07-28',
            Accept: 'application/json',
          },
        };
        try {
          const { data } = await axios.request(options);
          setContactDetails(data.contact);
          console.log('Contact Details:', data.contact);
        } catch (error) {
          console.error('Error fetching contact details:', error);
        }
      } else {
        console.error('Access token not available.');
      }
    };
    fetchContactDetails();
  }, [contactId]);

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    setIsUpdated(true);
  };

  const handleUpdate = async () => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      const updatedFields = {
        firstName: contactDetails.firstName,
        lastName: contactDetails.lastName,
        email: contactDetails.email,
        phone: contactDetails.phone,
        address1: contactDetails.address1 || null,
        city: contactDetails.city || null,
        state: contactDetails.state || null,
        postalCode: contactDetails.postalCode || null,
        country: contactDetails.country,
        companyName: contactDetails.companyName || null,
        website: contactDetails.website || null,
        customFields: contactDetails.customFields.map((field) => ({
          id: field.id,
          value: field.value || null,
        })),
      };
  
      const options = {
        method: 'PUT',
        url: `https://services.leadconnectorhq.com/contacts/${contactId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Version: '2021-07-28',
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        data: updatedFields,
      };
  
      try {
        await axios.request(options);
        setIsUpdated(false);
        console.log('Contact details updated successfully.');
      } catch (error) {
        console.error('Error updating contact details:', error);
      }
    } else {
      console.error('Access token not available.');
    }
  };

  if (!contactDetails) {
    return <Text>Loading patient details...</Text>;
  }

  return (
    <Box>
      <HStack mb={4}>
        <IconButton icon={<ArrowBackIcon />} aria-label="Back" onClick={handleBackClick} mr={2} />
        <Heading as="h1" size="2xl">
          Patient Details
        </Heading>
      </HStack>
      <VStack align="start" spacing={4}>
        <HStack>
          <Text fontWeight="bold">First Name:</Text>
          <Input
            name="firstName"
            value={contactDetails.firstName}
            onChange={handleChange}
          />
        </HStack>
        <HStack>
          <Text fontWeight="bold">Last Name:</Text>
          <Input
            name="lastName"
            value={contactDetails.lastName}
            onChange={handleChange}
          />
        </HStack>
        <HStack>
          <Text fontWeight="bold">Email:</Text>
          <Input
            name="email"
            value={contactDetails.email}
            onChange={handleChange}
          />
        </HStack>
        <HStack>
          <Text fontWeight="bold">Phone:</Text>
          <Input
            name="phone"
            value={contactDetails.phone}
            onChange={handleChange}
          />
        </HStack>
        <HStack>
          <Text fontWeight="bold">Date of Birth:</Text>
          <Input
            name="dateOfBirth"
            value={formatDate(contactDetails.dateOfBirth)}
            onChange={handleChange}
          />
        </HStack>
        <HStack>
          <Text fontWeight="bold">Street:</Text>
          <Input
            name="address1"
            value={contactDetails.address1}
            onChange={handleChange}
          />
        </HStack>
        <HStack>
          <Text fontWeight="bold">City:</Text>
          <Input
            name="city"
            value={contactDetails.city}
            onChange={handleChange}
          />
        </HStack>
        <HStack>
          <Text fontWeight="bold">State:</Text>
          <Input
            name="state"
            value={contactDetails.state}
            onChange={handleChange}
          />
        </HStack>
        <HStack>
          <Text fontWeight="bold">Zip Code:</Text>
          <Input
            name="postalCode"
            value={contactDetails.postalCode}
            onChange={handleChange}
          />
        </HStack>
        <HStack>
          <Text fontWeight="bold">Country:</Text>
          <Input
            name="country"
            value={contactDetails.country}
            onChange={handleChange}
          />
        </HStack>
        <Divider />
        <Text fontWeight="bold">Custom Fields:</Text>
        {contactDetails.customFields.map((field, index) => (
          <HStack key={index}>
            <Text fontWeight="bold">{field.id}:</Text>
            <Input
              name={`customFields[${index}].value`}
              value={field.value}
              onChange={handleChange}
            />
          </HStack>
        ))}
        <Button
          mt={4}
          colorScheme="blue"
          onClick={handleUpdate}
          isDisabled={!isUpdated}
        >
          Update
        </Button>
      </VStack>
    </Box>
  );
}

export default PatientDetails;