import React from 'react';
import { Box, Heading, } from '@chakra-ui/react';

const VoiceAgent = () => {

  return (
    <Box>
      <Heading as="h1" size="2xl" mb={4}>
        Voice Agent
      </Heading>
    </Box>
  );
};

export default VoiceAgent;