// components/Patients.js
import React, { useEffect, useState } from 'react';
import {
  Box,
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  Button,
  Flex,
  Spinner,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function formatDate(dateString) {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(dateString).toLocaleDateString(undefined, options);
}

function formatPhone(phoneNumber) {
  if (!phoneNumber) return '';
  const cleaned = phoneNumber.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
  }
  return phoneNumber;
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function Patients() {
  const [contactsData, setContactsData] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchContactsData();
  }, []);

  const fetchContactsData = async (query = '') => {
    setIsLoading(true);
    const accessToken = localStorage.getItem('accessToken');
    const locationId = localStorage.getItem('locationId');

    if (accessToken && locationId) {
      const options = {
        method: 'GET',
        url: 'https://services.leadconnectorhq.com/contacts/',
        params: { locationId, query },
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Version: '2021-07-28',
          Accept: 'application/json',
        },
      };

      try {
        const { data } = await axios.request(options);
        setContactsData(data);
        console.log('Contacts Data:', data);
      } catch (error) {
        console.error('Error fetching contacts data:', error);
      } finally {
        setIsLoading(false);
      }
    } else {
      console.error('Access token or location ID not available.');
      setIsLoading(false);
    }
  };

  const handleSearch = () => {
    fetchContactsData(searchQuery);
  };

  const handleRowClick = (contactId) => {
    navigate(`/portal/patients/${contactId}`);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleAddNewPatient = () => {
    navigate('/portal/patients/new-patient');
  };

  return (
    <Box>
      <Heading as="h1" size="2xl" mb={4}>
        Patients
      </Heading>
      <Flex justify="space-between" mb={4}>
        <Flex>
          <Input
            placeholder="Search Patients"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={handleKeyDown}
            width="400px"
            mr={2}
          />
          <Button onClick={handleSearch}>Search</Button>
        </Flex>
        <Flex>
          <Button colorScheme="blue" onClick={handleAddNewPatient}>
            Add New Patient
          </Button>
        </Flex>
      </Flex>
      {isLoading ? (
        <Flex justifyContent="center" alignItems="center" height="200px">
          <Spinner size="xl" />
        </Flex>
      ) : contactsData ? (
        contactsData.contacts.length > 0 ? (
          <Table>
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Email</Th>
                <Th>Phone</Th>
                <Th>Date Added</Th>
              </Tr>
            </Thead>
            <Tbody>
              {contactsData.contacts.map((contact) => (
                <Tr
                  key={contact.id}
                  cursor="pointer"
                  _hover={{ bg: 'gray.100' }}
                  onClick={() => handleRowClick(contact.id)}
                >
                  <Td>
                    {capitalizeFirstLetter(contact.firstName)}{' '}
                    {capitalizeFirstLetter(contact.lastName)}
                  </Td>
                  <Td>{contact.email}</Td>
                  <Td>{formatPhone(contact.phone)}</Td>
                  <Td>{formatDate(contact.dateAdded)}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : (
          <Text textAlign="center">Aw Shucks! We found no one!</Text>
        )
      ) : null}
    </Box>
  );
}

export default Patients;